
import { defineComponent, ref, onMounted, nextTick, computed } from 'vue'
import dayjs from 'dayjs'
import { usePrint } from '@/use/usePrint'
import { concatAddress } from '@/utils/address'
import { useRouter } from 'vue-router'
import { chunk } from '@/utils/print'
import ProgressLoading from '@/components/core/ProgressLoading.vue'
import { ORDER_PATH } from '@/constants/route'
import { userCurrency } from '@/use/userCurrency'
import * as htmlToImage from 'html-to-image'
import { LOGO_COURIER, COURIER_NAME } from '@/constants/shippingEnum'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import { useMiddleAccount } from '@/use/middleAccount'
import { isIOS } from '@/utils/validate'
import { ORDER_EXPORT_NAME } from '@/constants/orderEnum'

export default defineComponent({
	name: 'ShippingSix',
	components: {ProgressLoading},
	emits: ['onSuccess'],
	setup(_, { emit }) {
		const newOrderList = ref<any>([])
		const {
			sliceSorthingFlash,
			exportToPDF,
			slicePhoneNo,
			productName,
			isVisibleCODAmount,
			isVisibleProduct,
			onMaskPhoneNumber,
			isHeaderBooking,
			isSortingCode,
			isThaiPost,
			isFlash,
			isJAndT,
			isSortingLineCode,
			isNinja,
			onMarkingShipmentPhoneNo,
			isKerry,
			isNinjaAndKerry,
			isSPX,
			spxServiceType,
			isVisibleBoxSizeAndWeight,
		} = usePrint()
		const { currencyMoneyDigits } = userCurrency()
		const shippingStore = useShippingStore()
		const router = useRouter()
		const multipleRef: any = []
		const setItemRef = (el: any) => {
			if (el) {
				multipleRef.push(el)
			}
		}
		const loading = ref<boolean>(false)
		const countOrder = ref<number>(0)
		const orderID = computed(() => shippingStore.getterOrderListId)
		const { isChannelType, isDealerType } = useMiddleAccount()
		const typeExport = ORDER_EXPORT_NAME.SHIPPING_LABEL

		const saveImageToPDF = async () => {
			try {
				loading.value = true
				const allImageBase64: any = []
				const newArrayHTML = chunk(multipleRef, 5)

				const toJpegOption = { quality: 1, pixelRatio: 2 }
				for (let idx = 0; idx < newArrayHTML.length; idx++) {
					const list = newArrayHTML[idx].map((each: any) => {
						// อันนี้ hack lib ให้ mobile เนื่องจาก lib html-to-image มีปัญหา
						// เลยต้องแก้ตามท่านี้ https://github.com/bubkoo/html-to-image/issues/52
						// ไม่สวย และน่าเกลียดไปหน่อย แต่ทำใจ
						if (isIOS()) {
							return htmlToImage.toJpeg(each, toJpegOption)
								.then(() => {
									return htmlToImage.toJpeg(each ,toJpegOption)
								})
								.then(() => {
									return htmlToImage.toJpeg(each ,toJpegOption)
								})
								.then(() => {
									return htmlToImage.toJpeg(each ,toJpegOption)
								})
						}
						// desktop เหมือนเดิมปกติ
						return htmlToImage.toJpeg(each, toJpegOption)
					})
					const resolvePromise = await Promise.all(list)
					allImageBase64.push(...resolvePromise)
					countOrder.value = allImageBase64.length
				}

				const template: any = {
					basePdf: { width: 210, height: 297 },
					schemas: [
						{
							image0: {
								position: { x: 0, y: 0.5 },
								width: 105,
								height: 98.5,
								type: 'image',
							},
							image1: {
								position: { x: 105, y: 0.5 },
								width: 105,
								height: 98.5,
								type: 'image',
							},
							image2: {
								position: { x: 0, y: 99.5 },
								width: 105,
								height: 99,
								type: 'image',
							},
							image3: {
								position: { x: 105, y: 99.5 },
								width: 105,
								height: 99,
								type: 'image',
							},
							image4: {
								position: { x: 0, y: 198.5 },
								width: 105,
								height: 99,
								type: 'image',
							},
							image5: {
								position: { x: 105, y: 198.5 },
								width: 105,
								height: 99,
								type: 'image',
							},
						},
					],
				}

				const customInputs: any = []
				chunk(allImageBase64, 6).forEach((eachArray: any) => {
					let object = {}
					eachArray.forEach((eachArray2: any, i: number) => {
						object = {
							...object,
							[`image${i}`]: eachArray2,
						}
					})
					customInputs.push(object)
				})
				await exportToPDF('ShippingLabel1x6', customInputs, template)
				loading.value = false
				emit('onSuccess')
			} catch (error) {
				loading.value = false
				emit('onSuccess')
			}
		}

		const styleLogoImage = (item: any) => {
			switch (item.courier.name) {
			case COURIER_NAME.THAIPOST:
				return 'width: 155px; height: 50px'
			case COURIER_NAME.FLASH:
				return 'width: 60px; height: 30px'
			case COURIER_NAME.NINJA_VAN:
				return 'width: 96px; height: 30px; margin-top: 20px'
			case COURIER_NAME.KERRY:
				return 'width: 115px; height: 45px; margin-top: 12px'
			case COURIER_NAME.SPX:
				return 'width: 75px; height: 33px; margin-top: 12px'
			default:
				return 'width: 116px;'
			}
		}

		const styleBoxSize = (item: any,type: string) => {
			if(isVisibleBoxSizeAndWeight(item)){
				if(type === 'table-box'){
					return 'height: 30px;'
				}
				if(type === 'receiver-box'){
					return 'min-height: 70px;'
				}
				
			}
		}

		const spanLogoCourier = (item: any) => {
			if ((item.courier.name === COURIER_NAME.THAIPOST && isChannelType.value) || isKerry(item)) {
				return 24
			} else if(item.courier.name === COURIER_NAME.SPX) {
				return 5
			}
			return isSortingCode(item) ? 7 : 12
		}

		onMounted(async () => {
			newOrderList.value = shippingStore.orderList as any
			if(newOrderList.value && newOrderList.value.length > 0) {
				await nextTick()
				saveImageToPDF()
			} else {
				router.push({path: ORDER_PATH})
			}
		})

		return {
			newOrderList,
			dayjs,
			countOrder,
			orderID,
			loading,
			typeExport,
			LOGO_COURIER,
			COURIER_NAME,
			isChannelType,
			isDealerType,
			sliceSorthingFlash,
			concatAddress,
			setItemRef,
			slicePhoneNo,
			isVisibleCODAmount,
			currencyMoneyDigits,
			productName,
			isVisibleProduct,
			onMaskPhoneNumber,
			isHeaderBooking,
			styleLogoImage,
			isSortingCode,
			isThaiPost,
			isFlash,
			isJAndT,
			isSortingLineCode,
			isNinja,
			onMarkingShipmentPhoneNo,
			spanLogoCourier,
			isKerry,
			isNinjaAndKerry,
			isSPX,
			spxServiceType,
			styleBoxSize,
			isVisibleBoxSizeAndWeight
		}
	},
})
